import '../App.css';
import Header from '../header';
// Importar los íconos de react-icons
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaGlobe } from 'react-icons/fa';

function Contacto() {
  return (
    <div className="fondo-mas">
      <Header />
      <h2 className="titulos">Contacto</h2>
      <div className="texto">
        <p>Somos HC FOLDER</p>
        <p>
          <FaPhone className="resaltado" style={{ marginRight: '8px' }} />
          <strong className="resaltado">Teléfono:</strong> 442 449 4150
        </p>
        <p>
          <FaGlobe className="resaltado" style={{ marginRight: '8px' }} />
          <strong className="resaltado">Website:</strong> <a href="http://www.hcfolder.com" target="_blank" rel="noopener noreferrer">www.hcfolder.com</a>
        </p>
        <p>
          <FaEnvelope className="resaltado" style={{ marginRight: '8px' }} />
          <strong className="resaltado">E-Mail:</strong> <a href="mailto:ventastecnicas@hcpromedical.com">ventastecnicas@hcpromedical.com</a>
        </p>
        <p>
          <FaMapMarkerAlt className="resaltado" style={{ marginRight: '8px' }} />
          <strong className="resaltado">Ubicación:</strong> Anillo Vial, Fray Junípero Serra km número 20012, Col. El Salitre, C.P. 76127
        </p>
        <p>Todos los derechos reservados © 2024</p>
      </div>
      <iframe
        title="Formulario de suscripción HC Folder"
        width="540"
        height="305"
        src="https://14df18d2.sibforms.com/serve/MUIFAFGgzY7OtvGGJ9-ABYKBMyOLInm2Vi_oZJhEvNrg_O_ihmo33A_z4ERYHOX2dgPJAsKWB22BRnFpjagrWfhY4CX51P3JRpWq4cygd3QIXRpS012K4s3im4I1mQ8xBE7y6Vbgtwuhp7yIZ71Z-6SxSlevbLbhG92bnFHTlwJK-DNymmhI-eKEb9tsQ5neKQhWis--HaMIhe1r"
        style={{ marginTop: '20px', maxWidth: '100%', height: '420px' }}
      />
    </div>
  );
}

export default Contacto;
