import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../estilos/Registro.css';
import Header from '../header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Registro = () => {
  const [formData, setFormData] = useState({
    nombreUs: '',
    apellidoUs: '',
    telefonoUs: '',
    correoUs: '',
    contraUs: '',
    statusUs: 1,
    tipoUs: 2,
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'telefonoUs') {
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validate = () => {
    const newErrors = {};

    // Validación de campos vacíos
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = 'Este campo es obligatorio.';
      }
    });

    // Validación del teléfono: Debe tener exactamente 10 dígitos
    if (!formData.telefonoUs.match(/^\d{10}$/)) {
      newErrors.telefonoUs = 'El teléfono debe tener exactamente 10 dígitos.';
    }

    // Validación del correo: Debe ser válido y terminar en .com o ser de Gmail
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    if (!emailRegex.test(formData.correoUs)) {
      newErrors.correoUs = 'El correo debe ser válido y terminar en .com o ser de Gmail.';
    }

    // Validación de la contraseña
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_\-+=<>?{}[\]~]).{6,}$/;
    if (!passwordRegex.test(formData.contraUs)) {
      newErrors.contraUs = 'La contraseña debe tener al menos 6 caracteres, una letra mayúscula y un carácter especial.';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error('Por favor revisa los campos del formulario.');
    } else {
      try {
        const response = await fetch(
          'https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/Registro/registro',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          if (errorData.message && errorData.message.includes('correo ya registrado')) {
            throw new Error('El correo ingresado ya se encuentra en uso.');
          }
          throw new Error('Error en la solicitud: ' + response.status);
        }

        const data = await response.json();
        console.log('Registro exitoso:', data);

        setFormData({
          nombreUs: '',
          apellidoUs: '',
          telefonoUs: '',
          correoUs: '',
          contraUs: '',
          statusUs: 1,
          tipoUs: 2,
        });

        setErrors({});
        toast.success('Registro exitoso');
        navigate('/Login');
      } catch (error) {
        console.error('Error al registrar:', error);
        setErrors({ apiError: error.message });
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="fondo-login">
      <Header />
      <div className="login-container" style={{ marginTop: '20px' }} >
        <h2 className="titulos-no-move" style={{ marginBottom: '10px' }}>Nuevo Doctor</h2>
        <form className="registro-formulario" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="nombreUs">Nombre:</label>
            <input
              type="text"
              id="nombreUs"
              name="nombreUs"
              value={formData.nombreUs}
              onChange={handleChange}
              required
              className="global-input"
            />
            {errors.nombreUs && <span className="error">{errors.nombreUs}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="apellidoUs">Apellido:</label>
            <input
              type="text"
              id="apellidoUs"
              name="apellidoUs"
              value={formData.apellidoUs}
              onChange={handleChange}
              required
              className="global-input"
            />
            {errors.apellidoUs && <span className="error">{errors.apellidoUs}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="telefonoUs">Teléfono:</label>
            <input
              type="tel"
              id="telefonoUs"
              name="telefonoUs"
              value={formData.telefonoUs}
              onChange={handleChange}
              required
              className="global-input"
            />
            {errors.telefonoUs && <span className="error">{errors.telefonoUs}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="correoUs">Correo:</label>
            <input
              type="email"
              id="correoUs"
              name="correoUs"
              value={formData.correoUs}
              onChange={handleChange}
              required
              className="global-input"
            />
            {errors.correoUs && <span className="error">{errors.correoUs}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="contraUs">Contraseña:</label>
            <input
              type="password"
              id="contraUs"
              name="contraUs"
              value={formData.contraUs}
              onChange={handleChange}
              required
              className="global-input"
            />
            {errors.contraUs && <span className="error">{errors.contraUs}</span>}
          </div>
          <button type="submit" className="form-boton">Registrar</button>
          {errors.apiError && <span className="error">{errors.apiError}</span>}
        </form>
        <ToastContainer autoClose={2000} closeButton={false} closeOnClick pauseOnHover draggable className="custom-toast" />
      </div>
    </div>
  );
};

export default Registro;
