import React, { useState } from 'react'; 
import '../estilos/login.css';
import { Link } from 'react-router-dom';
import Header from '../header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleValidate = async () => {
    try {
      const response = await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/validarDoc/${email}`, {
        method: 'GET',
        mode: 'cors',
      });
      if (!response.ok) {
        throw new Error('Error en la validación');
      }
      const validation = await response.json();
      return validation.existe;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/LoginApp/authenticate', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          correo: email,
          password: password,
        }),
      });

      if (!response.ok) {
        toast.error('Error en la autenticación');
        throw new Error('Error en la autenticación');
      }

      const data = await response.json();
      console.log(data);

      if(data.tipoUsuario !== 1){
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('usuario', email);
      }

      if (data.tipoUsuario === 2) {
        toast.success('Acceso permitido');
        const isDoctorValid = await handleValidate();
        if (isDoctorValid) {
          navigate('/Home');
        } else {
          navigate('/datosDoc');
        }
      } else if (data.tipoUsuario === 3) {
        toast.success('Acceso permitido');
        navigate('/Operador');
      } else if (data.message === "Credenciales Inválidas.") {
        toast.error('Credenciales incorrectas');
      } else {
        toast.error('Los pacientes no tienen acceso aquí');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="fondo-login">
      <Header />
      <div className="login-container">
        <h2 className="titulos-no-move">Iniciar Sesión</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label style={ { marginLeft: '15px' }}>Email:</label>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
              className="global-input"
            />
          </div>
          <div>
            <label style={ { marginLeft: '15px' }}>Contraseña:</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
              className="global-input"
            />
          </div>
          <button type="submit">Iniciar Sesión</button>
        </form>
        <Link to="/registro">
          <p style={{ marginTop: '15px', textAlign: 'center'}}> ¿No tienes una cuenta? Regístrate aquí</p>
        </Link>
      </div>
      <ToastContainer autoClose={2000} closeButton={false} closeOnClick pauseOnHover draggable className="custom-toast" />
    </div>
  );
};

export default Login;
