import '../App.css';
import Header from '../header';

function Acercade() {
  return (
    <div className="fondo-mas">
      <Header />
        <h2 className='titulos'>HC Folder: Tu socio para la gestión médica</h2>
      <div className="texto">
        <p>
          <strong className="resaltado">HC Folder</strong> es una innovadora aplicación diseñada para ayudar a los médicos a gestionar su consulta de manera eficiente. <strong className="resaltado">HC Promedical</strong> dueños de <strong className="resaltado">HC Folder</strong>, cuenta con más de <strong className="resaltado">25 años de experiencia</strong> en el sector de la salud, nuestro enfoque principal es brindar <strong className="resaltado">soluciones integrales</strong> que faciliten la atención médica y mejoren la experiencia del paciente.
        </p>
      </div>
      <div className="texto">
        <h3>¿Qué ofrece HC Folder?</h3>
        <ul>
          <li>
            <strong className="resaltado">Gestión de citas:</strong> Organiza tus citas de manera eficiente, reduciendo el tiempo de espera y mejorando la atención al paciente.
          </li>
          <li>
            <strong className="resaltado">Historial médico electrónico:</strong> Accede rápidamente al historial médico de tus pacientes, permitiendo una atención más personalizada y efectiva.
          </li>
          <li>
            <strong className="resaltado">Recetas electrónicas:</strong> Genera y envía recetas electrónicas de forma rápida y sencilla, mejorando la seguridad y la eficiencia en la atención médica.
          </li>
          <li>
            <strong className="resaltado">Soporte técnico:</strong> Contamos con un equipo de soporte disponible para resolver cualquier duda o problema que puedas tener con la aplicación.
          </li>
        </ul>
      </div>
      <div className="texto">
        <h3>¿Por qué elegir HC Folder?</h3>
        <ul>
          <li>
            <strong className="resaltado">Experiencia en salud:</strong> Con un profundo conocimiento del sector, hemos desarrollado soluciones que realmente satisfacen las necesidades de los médicos.
          </li>
          <li>
            <strong className="resaltado">Interfaz amigable:</strong> Nuestra aplicación es fácil de usar, lo que permite que los profesionales de la salud se concentren en lo que más importa: sus pacientes.
          </li>
          <li>
            <strong className="resaltado">Seguridad:</strong> Implementamos las mejores prácticas de seguridad para proteger la información de tus pacientes y asegurar su privacidad.
          </li>
          <li>
            <strong className="resaltado">Enfoque en el cliente:</strong> Nos comprometemos a escuchar y adaptar nuestras soluciones a las necesidades específicas de nuestros usuarios.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Acercade;
