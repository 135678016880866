import '../App.css';
import Header from '../header';

function Servicios() {
  return (
    <div className="fondo-mas">
      <Header />
      <h2 className='titulos'>Servicios para la Salud</h2>
      <div className="texto">
        <p><h3>¿Qué ofrecemos para el paciente?</h3></p>
        <p>
          Nuestro objetivo es brindar soluciones digitales de última generación para mejorar tu
          experiencia en salud. Nos especializamos en:
        </p>
        <ul>
          <li><span className="resaltado">Gestión de citas</span> para una atención sin contratiempos</li>
          <li><span className="resaltado">Chatbot de salud</span> para orientación en tiempo real</li>
          <li><span className="resaltado">Catálogo de hospitales</span> para que elijas la mejor opción</li>
          <li><span className="resaltado">Botones de emergencia</span> para asistencia rápida en situaciones críticas</li>
          <li><span className="resaltado">Almacenamiento seguro de datos médicos</span> para tus historiales y registros</li>
          <li><span className="resaltado">Expedientes de salud digitalizados</span> para acceso fácil y seguro</li>
          <li><span className="resaltado">Búsqueda de costos</span> en tu zona para que compares y elijas</li>
          <li><span className="resaltado">Especialidades médicas</span> para que encuentres al profesional adecuado</li>
          <li><span className="resaltado">Citas virtuales</span> para recibir atención desde donde estés</li>
        </ul>

        <p><h3>¿Qué ofrecemos para el doctor?</h3></p>
        <p>
          Simplificamos la vida profesional de los doctores con herramientas avanzadas para que se
          enfoquen en lo que mejor hacen: cuidar de sus pacientes.
        </p>
        <ul>
          <li><span className="resaltado">Gestión completa de citas</span> para optimizar cada consulta</li>
          <li><span className="resaltado">Generación de recetas</span> digital para mayor rapidez y seguridad</li>
          <li><span className="resaltado">Agenda de citas</span> que se adapta a su ritmo de trabajo</li>
          <li><span className="resaltado">Gestión de tiempo personalizada</span> para maximizar productividad</li>
        </ul>
        <p>Todo con un enfoque en calidad, confidencialidad y tecnología de punta.</p>
      </div>
    </div>
  );
}

export default Servicios;
