import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { FaUser, FaSignOutAlt, FaStethoscope, FaClinicMedical } from 'react-icons/fa'; 
import '../estilos/docstyles.css';

const Home = () => {
  const location = useLocation();
  const isRootPath = location.pathname === "/Home";

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('usuario');
  };

  return (
    <div className="doctor-container">
      <div className="doctor-sidebar">
        <div className="doctor-sidebar-header">
          <img src="/headerLogo.png" alt="Logo Empresa" className="doctor-logo" />
          <h2>Panel de Doctores</h2>
        </div>
        <div className="doctor-sidebar-links">
          <Link to="disponibles" className="doctor-link">
            <FaUser className="doctor-link-icon" />
            Disponibles
          </Link>
          <Link to="aceptadas" className="doctor-link">
            <FaStethoscope className="doctor-link-icon" />
            Aceptadas
          </Link>
          <Link to="finalizadas" className="doctor-link">
            <FaClinicMedical className="doctor-link-icon" />
            Finalizadas
          </Link>
        </div>
        <Link to="/" onClick={handleLogout} className="doctor-bye">
          <FaSignOutAlt className="doctor-link-icon" />
          Cerrar Sesión
        </Link>
      </div>
      <div className="doctor-content">
        {isRootPath && (
          <>
            <h1 className="doctor-welcome-text">Bienvenido al módulo de administración</h1>
            <p className="doctor-instructions">Seleccione una opción del menú para continuar</p>
          </>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
