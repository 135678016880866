import './estilos/header.css';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="navbar-transparent">
      <div className="container-header">
        <h1 className="titulos-no-move" style={{ marginTop: '25px' }}>HC Folder</h1>
        <nav className="nav-links">
          <Link to="/">Inicio</Link>
          <Link to="/Acerca-de">Acerca de</Link>
          <Link to="/Servicios">Servicios</Link>
          <Link to="/Contacto">Contacto</Link>
        </nav>
        <div className="buttons-container">
          <Link to="/Login">
            <button className="buttonHeader">
              Iniciar Sesión
            </button>
          </Link>
          
          <Link to="/Registro">
          <button className="buttonHeader" style={{ backgroundColor: '#2ecc71' }}>
            Registro
          </button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
