import { useEffect, useState } from "react";
import './userstyles.css';

const Doctores = () => {
    const [doctores, setDoctores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalAltaOpen, setIsModalAltaOpen] = useState(false);
    const [correoDoctores, setCorreo] = useState([]);
    const [newDoctor, setNewDoctor] = useState({
        correoUs:'',
        cedDoc:'',
        genDoc:'',
        validacionDoc:true,
        cuentaDoc:'',
        bancoDoc:'',
        linkDoc:'',
    })

    const fetchCorreoDoctores = async () => {
        try {
            const response = await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/Disponibles`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                console.error('Error', response.statusText);
                return;
            }

            const data = await response.json();
            const correos = data.map(doctor => doctor.correoUs); 
            setCorreo(correos);
    
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    };
    
    const fetchDoctores = async () => {
        try {
            const response = await fetch(
                "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/Todos"
            );
            if (!response.ok) {
               throw new Error("Error al obtener los doctores");
            }
            const data = await response.json(); 
            setDoctores(data);

            setLoading(false); 

        } catch (err) {
            setError("Hubo un error al obtener los datos"); 
            setLoading(false); 
        
        }
    };

    useEffect(() => {
        fetchDoctores();
        fetchCorreoDoctores(); 
    }, []); 

    const altaDoctor = async () =>{
        try{
            await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/Alta/${newDoctor.correoUs}`,{
                method:'POST',
                headers:{
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cedDoc: newDoctor.cedDoc,
                    genDoc: newDoctor.genDoc,
                    validacionDoc: newDoctor.validacionDoc,
                    cuentaDoc: newDoctor.cuentaDoc,
                    bancoDoc: newDoctor.bancoDoc,
                    linkDoc: newDoctor.linkDoc
                }),
            });
            closeModal();
            fetchDoctores();
        } catch (err) {
            console.error("Error al enviar datos:", err);
        }
    };

    const closeModal = async () => {
        await fetchDoctores();
        setIsModalOpen(false);
    };
    
    const desactivarDoctor = async (id) => {
        try {
            const response = await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/Desactivar/${id}`, {
                method: 'PUT',
            });

            if (!response.ok) {
                throw new Error('Error al desactiva doctor');
            }
            window.location.reload();
        } catch (err) {
            setError("Error al desactivar al doctor");
        }
    };

    const actualizarDoctor = async () => {
        try {
            const response =await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/Actualizar",{
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idDoc: selectedDoc.idDoc,
                    cedDoc:selectedDoc.cedDoc,
                    genDoc:selectedDoc.genDoc,
                    idUs:selectedDoc.idUs,
                    validacionDoc: selectedDoc.validacionDoc,
                    cuentaDoc:selectedDoc.cuentaDoc,
                    bancoDoc:selectedDoc.bancoDoc,
                }),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.error("Error en la respuesta:", errorData);
                throw new Error("Error al actualizar el doctor");
            }
    
            await fetchDoctores();
            closeModal();
        } catch (err) {
            setError("Hubo un error al actualizar el doc");
        }
    };
    

    const handleRowClick = (doctor) => {
        setSelectedDoc(doctor);
        setIsModalOpen(true);
    };

    const handleNewInputChange = (e) =>{
        const {name,value} =e.target;
        setNewDoctor((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } =e.target;
        setSelectedDoc((prevDoctor) => ({
            ...prevDoctor,
            [name]: value,
        }));
    };

    const closeModalAlta = () => {
        setIsModalAltaOpen(false);
        setNewDoctor({
            correoUs:'',
            cedDoc:'',
            genDoc:'',
            validacionDoc:true,
            cuentaDoc:'',
            bancoDoc:'',
            linkDoc:''
        });
    };

    if (error) {
        return <div>{error}</div>;
    }
    if (loading) {
        return <div>Cargando doctores...</div>;
    }


    return (
        <div>
            <h1>Lista de Doctores</h1>
            <button onClick={() => setIsModalAltaOpen(true)}>Agregar Doctor</button>
            <div className="table-container">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>ID de Doctor</th>
                            <th>Cédula</th>
                            <th>Género</th>
                            <th>Id de Usuario</th>
                            <th>Cuenta Bancaria</th>
                            <th>Nombre del Banco</th>
                            <th>Link de consulta medica</th>
                        </tr>
                    </thead>
                    <tbody>
                        {doctores.map((doctor) => (
                            <tr key={doctor.idDoc} onClick={() => handleRowClick(doctor)}>
                                <td>{doctor.idDoc}</td>
                                <td>{doctor.cedDoc}</td>
                                <td>{doctor.genDoc === "M" ? "Masculino" : "Femenino"}</td>
                                <td>{doctor.idUs}</td>
                                <td>{doctor.cuentaDoc}</td>
                                <td>{doctor.bancoDoc}</td>
                                <td>{doctor.linkDoc}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {isModalOpen && selectedDoc &&(
                <div className="modal">
                    <div className="modal-content">
                        <h2 className="titulos-no-move">Editar Doctor</h2>
                        <label>ID del Doctor:</label>
                        <input
                            type="text"
                            name="idDoc"
                            value={selectedDoc.idDoc}
                            onChange={handleInputChange}
                        />
                        <label>Cedula:</label>
                        <input
                            type="text"
                            name="cedDoc"
                            value={selectedDoc.cedDoc}
                            onChange={handleInputChange}
                        />
                        <label>Sexo:</label>
                        <input
                            type="text"
                            name="genDoc"
                            value={selectedDoc.genDoc}
                            onChange={handleInputChange}
                        />
                        <label>ID usuario:</label>
                        <input
                            type="text"
                            name="idUs"
                            value={selectedDoc.idUs}
                            onChange={handleInputChange}
                        />
                        <label>Cuenta Bancaria:</label>
                        <input
                            type="text"
                            name="cuentaDoc"
                            value={selectedDoc.cuentaDoc}
                            onChange={handleInputChange}
                        />
                        <label>Nombre del Banco:</label>
                        <input
                            type="text"
                            name="bancoDoc"
                            value={selectedDoc.bancoDoc}
                            onChange={handleInputChange}
                        />
                        <button className="modal-submit" onClick={actualizarDoctor}>
                            Aceptar
                            </button>
                        <button className="modal-delete" onClick={() => desactivarDoctor(selectedDoc.idDoc)}>
                            Desactivar
                        </button>
                        <button className="modal-close" onClick={closeModal}>
                            Cerrar
                        </button>
                    </div>
                </div>
            )}
            {isModalAltaOpen && (
                <div className="modal">
                    <div className="modal-content">

                        <h2 className="titulos-no-move">Alta de doctor</h2>

                        <label>Seleccione un correo Doctor</label>
                        <select onChange={(e) => setNewDoctor(prev => ({ ...prev, correoUs: e.target.value }))}>
                            <option value="" disabled selected>Seleccione un correo</option>
                            {correoDoctores.map((correo, index) => (
                                <option key={index} value={correo}>
                                    {correo}
                                </option>
                            ))}
                        </select>

                        <label>Cédula:</label>
                        <input
                            type="text"
                            name="cedDoc"
                            value={newDoctor.cedDoc}
                            onChange={handleNewInputChange}
                        />
                        <label>Género:</label>
                        <input
                            type="text"
                            name="genDoc"
                            value={newDoctor.genDoc}
                            onChange={handleNewInputChange}
                        />
                        <label>Cuenta Bancaria:</label>
                        <input
                            type="text"
                            name="cuentaDoc"
                            value={newDoctor.cuentaDoc}
                            onChange={handleNewInputChange}
                        />
                        <label>Nombre del Banco:</label>
                        <input
                            type="text"
                            name="bancoDoc"
                            value={newDoctor.bancoDoc}
                            onChange={handleNewInputChange}
                        />
                        <button onClick={altaDoctor}>Aceptar</button>
                        <button onClick={closeModalAlta}>Cerrar</button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Doctores;