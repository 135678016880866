import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Inicio from './componentes/Inicio';
import Servicios from './componentes/Servicios';
import Contacto from './componentes/Contacto';
import Login from './componentes/Login';
import Registro from './componentes/registro';
import Acercade from './componentes/Acercade';
import Recetas from './doctor/Recetas';
import Aceptadas from './doctor/Aceptadas';
import Finalizadas from './doctor/Finalizadas';
import Disponibles from './doctor/Disponibles';
import DatosDoc from './doctor/DatosDoc';
import Mycitas from './doctor/Mycitas';
import Operador from './admin/Operador';
import Usuarios from './admin/Usuarios';
import Pacientes from './admin/Pacientes';
import Doctores from './admin/Doctores';
import Hospitales from './admin/Hospitales';
import Recursos from './admin/Recursos';
import DetalleHospital from './admin/DetalleHospital';
import Noticias from './admin/Noticias';
import PrivateRoute from './PrivateRoute';
import Home from './doctor/Home';
import Reportes from './admin/Reportes';

const AppRouter = () => {
  return (
    <Router>
      <div>
        <main>
          <Routes>
            <Route path="*" element={<Inicio />} />
            <Route path="/" element={<Inicio />} />
            <Route path="/Inicio" element={<Inicio />} />
            <Route path="/Acerca-de" element={<Acercade />} />
            <Route path="/Servicios" element={<Servicios />} />
            <Route path="/Contacto" element={<Contacto />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Registro" element={<Registro />} />

            {/* Rutas protegidas usando PrivateRoute */}
            <Route path="/Disponibles" 
              element={
                <PrivateRoute>
                  <Disponibles />
                </PrivateRoute>
              } 
            />
            <Route path="/Aceptadas" 
              element={
                <PrivateRoute>
                  <Aceptadas />
                </PrivateRoute>
              } 
            />
            <Route path="/Finalizadas" 
              element={
                <PrivateRoute>
                  <Finalizadas />
                </PrivateRoute>
              } 
            />
            <Route path="/DatosDoc" 
              element={
                <PrivateRoute>
                  <DatosDoc />
                </PrivateRoute>
              } 
            />
            <Route path="/Mycitas" 
              element={
                <PrivateRoute>
                  <Mycitas />
                </PrivateRoute>
              } 
            />
            <Route path="/Recetas" 
              element={
                <PrivateRoute>
                  <Recetas />
                </PrivateRoute>
              } 
            />

            {/* Rutas anidadas dentro del componente Operador */}
            <Route path="/Operador" 
              element={
                <PrivateRoute>
                  <Operador />
                </PrivateRoute>
              }
            >
              <Route path="hospitales" element={<Hospitales />} />
              <Route path="pacientes" element={<Pacientes />} />
              <Route path="usuarios" element={<Usuarios />} />
              <Route path="doctores" element={<Doctores />} />
              <Route path="detalleHospital" element={<DetalleHospital />} />
              <Route path="recursos" element={<Recursos />} />
              <Route path="noticias" element={<Noticias />} />
              <Route path="reportes" element={<Reportes />} />
            </Route>

            {/* Rutas anidadas dentro del componente Home */}
            <Route path="/Home" 
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            >
              <Route path="finalizadas" element={<Finalizadas />} />
              <Route path="pacientes" element={<Pacientes />} />
              <Route path="disponibles" element={<Disponibles />} />
              <Route path="aceptadas" element={<Aceptadas />} />
              <Route path="detalleHospital" element={<DetalleHospital />} />
              <Route path="recursos" element={<Recursos />} />
              <Route path="noticias" element={<Noticias />} />
            </Route>
            
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default AppRouter;
