import React, { useEffect, useState } from "react";
import './userstyles.css';

const Usuarios = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalAltaOpen, setIsModalAltaOpen] = useState(false);
    const [newUsuario, setNewUsuario] = useState({
        nombreUs: '',
        apellidoUs: '',
        telefonoUs: '',
        correoUs: '',
        contraUs: '',
        statusUs: 0,
        tipoUs: 0
    })

//funcion para optener todos los usuarios.
    const fetchUsuarios = async () => {
        setLoading(true);
            try {
                const response = await fetch(
                    "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/QR/api/Todos"
                );
                if (!response.ok) {
                    throw new Error("Error al obtener los usuarios");
                }
                const data = await response.json();
                setUsuarios(data);
                setLoading(false);
            } catch (err) {
                setError("Hubo un error al obtener los datos");
                setLoading(false);
            }
        };

    useEffect(() => {
        fetchUsuarios();
    }, []);


    //funcion para hacer la alta de usuarios
    const altaUsuario = async () => {
        try{
            const response = await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/Registro/registro", {
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nombreUs: newUsuario.nombreUs,
                    apellidoUs: newUsuario.apellidoUs,
                    telefonoUs: newUsuario.telefonoUs,
                    correoUs: newUsuario.correoUs,
                    contraUs: newUsuario.contraUs,
                    statusUs: newUsuario.statusUs,
                    tipoUs: newUsuario.tipoUs,
                }),
            });
            if(!response.ok){
                throw new Error("Error al dar de alta el nuevo usuario");
            }
            await fetchUsuarios();
            closeModalAlta();
        } catch (err) {
            setError(console.log());
        }
    };

    //funcion para desactivar un usuario
    const desactivarUsuario = async (id) => {
        try {
            const response = await fetch (`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/QR/api/Desactivar/${id}`, {
                method: 'PUT',
            });
            if (!response.ok){
                throw new Error('Error al desactivar al usuario');
            }
            await fetchUsuarios();
            closeModal();
        }catch (err){
            setError(console.log());

        }
    };

    const actualizarUsuario = async () => {
        try{
            const response = await fetch ("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/QR/api/Actualizar",{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nombreUs: selectedUser.nombreUs,
                    apellidoUs: selectedUser.apellidoUs,
                    telefonoUs: selectedUser.telefonoUs,
                    correoUs: selectedUser.correoUs,
                    statusUs: selectedUser.statusUs,
                    tipoUs: selectedUser.tipoUs
                }),
            });
            if (!response.ok) {
                throw new Error("Error al actualizar usuario");
            }
            await fetchUsuarios();
            closeModal();
        } catch (err) {
            setError(console.log());
        }
    };

    const handleRowClick = (usuario) => {
        setSelectedUser(usuario);
        setIsModalOpen(true);
    };

    const handleNewInputChange = (e) => {
        const { name, value } = e.target;
        setNewUsuario((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeModalAlta = () => {
        setIsModalAltaOpen(false);
        setNewUsuario({
            nombreUs: '',
            apellidoUs: '',
            telefonoUs: '',
            correoUs: '',
            contraUs: '',
            statusUs: 0,
            tipoUs: 0,
        });
    };

    if (error) {
        return <div>{error}</div>;
    }
    if (loading) {
        return <div>Cargando usuarios...</div>;
    }

    return (
        <div>
            <h1>Lista de Usuarios</h1>
            <button onClick={()=>setIsModalAltaOpen(true)}>Agregar un nuevo usuario</button>
            <div className="table-container">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Teléfono</th>
                            <th>Correo</th>
                            <th>Status</th>
                            <th>Tipo</th>
                            <th>ID de Usuario</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usuarios.map((usuario) => (
                            <tr key={usuario.idUs} onClick={() => handleRowClick(usuario)}>
                                <td>{usuario.nombreUs}</td>
                                <td>{usuario.apellidoUs}</td>
                                <td>{usuario.telefonoUs}</td>
                                <td>{usuario.correoUs}</td>
                                <td>{usuario.statusUs === 1 ? "Activo" : "Inactivo"}</td>
                                <td>{usuario.tipoUs === 1 ? "Paciente" : usuario.tipoUs === 2 ? "Doctor" : "Admin"}</td>
                                <td>{usuario.idUs}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {isModalOpen && selectedUser && (
                <div className="modal">
                    <div className="modal-content">
                        <h2 className="titulos-no-move">Editar Usuario</h2>
                        <label>Nombre:</label>
                        <input
                            type="text"
                            name="nombreUs"
                            value={selectedUser.nombreUs}
                            onChange={handleInputChange}
                        />
                        <label>Apellido:</label>
                        <input
                            type="text"
                            name="apellidoUs"
                            value={selectedUser.apellidoUs}
                            onChange={handleInputChange}
                        />
                        <label>Teléfono:</label>
                        <input
                            type="text"
                            name="telefonoUs"
                            value={selectedUser.telefonoUs}
                            onChange={handleInputChange}
                        />
                        <label>Correo:</label>
                        <input
                            type="text"
                            name="correoUs"
                            value={selectedUser.correoUs}
                            onChange={handleInputChange}
                        />
                        <label>Status:</label>
                        <select
                            name="statusUs"
                            value={selectedUser.statusUs}
                            onChange={handleInputChange}
                        >
                            <option value={1}>Activo</option>
                            <option value={0}>Inactivo</option>
                        </select>
                        <label>Tipo:</label>
                        <select
                            name="tipoUs"
                            value={selectedUser.tipoUs}
                            onChange={handleInputChange}
                        >
                            <option value={1}>Paciente</option>
                            <option value={2}>Doctor</option>
                            <option value={3}>Admin</option>
                        </select>
                        <button className="modal-submit" onClick={actualizarUsuario}>
                            Aceptar
                        </button>
                        <button className="modal-delete" onClick={() => desactivarUsuario(selectedUser.idUs)}>
                            Eliminar
                        </button>
                        <button className="modal-close" onClick={closeModal}>
                            Cerrar
                        </button>
                    </div>
                </div>
            )}
            {isModalAltaOpen &&(
                <div className="modal">
                    <div className="modal-content">
                        <h2 className="titulos-no-move">Agregar Usuario</h2>
                        <label>Nombre:</label>
                        <input
                            type="text"
                            name="nombreUs"
                            value={newUsuario.nombreUs}
                            onChange={handleNewInputChange}
                        />
                        <label>Apellido:</label>
                        <input
                            type="text"
                            name="apellidoUs"
                            value={newUsuario.apellidoUs}
                            onChange={handleNewInputChange}
                        />
                        <label>Telefono:</label>
                        <input
                            type="text"
                            name="telefonoUs"
                            value={newUsuario.telefonoUs}
                            onChange={handleNewInputChange}
                        />
                        <label>Correo:</label>
                        <input
                            type="text"
                            name="correoUs"
                            value={newUsuario.correoUs}
                            onChange={handleNewInputChange}
                        />
                        <label>Contraseña:</label>
                        <input
                            type="text"
                            name="contraUs"
                            value={newUsuario.contraUs}
                            onChange={handleNewInputChange}
                        />
                        <label>Status:</label>
                        <select
                            name="statusUs"
                            value={newUsuario.statusUs}
                            onChange={handleNewInputChange}
                        >
                            <option value={1}>Activo</option>
                            <option value={0}>Inactivo</option>

                        </select>
                        <select
                            name="tipoUs"
                            value={newUsuario.tipoUs}
                            onChange={handleNewInputChange}
                        >
                            <option value={1}>Paciente</option>
                            <option value={2}>Doctor</option>
                            <option value={3}>Admin</option>

                        </select>
                        <button className="modal-submit" onClick={altaUsuario}>
                            Aceptar
                        </button>
                        <button className="modal-close" onClick={closeModalAlta}>
                            Cerrar
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Usuarios;
