import React, { useState } from "react";
import '../App.css';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const DatosDoc = () => {
    const [formData, setFormData] = useState({
        cedDoc: "",
        genDoc: "",
    });

    const [errors, setErrors] = useState({});
    const usuario = localStorage.getItem('usuario');
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(
              `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/alta_doctor/${usuario}`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
              }
            );

            if (!response.ok) {
              const errorData = await response.json();
              if (errorData.message && errorData.message.includes('correo ya registrado')) {
                throw new Error('El correo ingresado ya se encuentra en uso.');
              }
              throw new Error('Error en la solicitud: ' + response.status);
            }

            const data = await response.json();
            console.log('Registro exitoso:', data);

            setFormData({
              cedDoc: '',
              genDoc: '',
            });

            setErrors({});
            toast.success("Registro exitoso");
            navigate('/Doctor');
        } catch (error) {
            console.error('Error al registrar:', error);
            setErrors({ apiError: error.message });
            toast.error(error.message);
        }
    };

    return (
        <div className="fondo">
            <form onSubmit={handleSubmit}>
                <div className="titulos">
                    <h2>Datos del doctor</h2>
                </div>

                <div className="resaltado-black">
                    <label htmlFor="cedDoc">Cédula del Doctor:</label>
                    <input
                        type="text"
                        id="cedDoc"
                        className="global-input" // Aplicando clase global
                        value={formData.cedDoc}
                        onChange={handleInputChange}
                        placeholder="Ingresa tu cédula"
                        required
                    />
                </div>

                <div className="resaltado-black">
                    <label htmlFor="genDoc">Género del Doctor:</label>
                    <select
                        id="genDoc"
                        className="form-select"
                        value={formData.genDoc}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Selecciona un género</option>
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                        
                    </select>
                    </div>
                    
                {errors.apiError && <p className="error">{errors.apiError}</p>}

                <button type="submit" className="btn btn-primary">Enviar</button>
            </form>
            <ToastContainer autoClose={2000} closeOnClick />
        </div>
    );
};

export default DatosDoc;
