import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { FaUser, FaSignOutAlt, FaStethoscope, FaClinicMedical, FaUserInjured, FaImage, FaHospital, FaNewspaper, FaPaperPlane } from 'react-icons/fa'; 
import './adminstyles.css';

const Operador = () => {
  const location = useLocation(); // Hook para obtener la ubicación actual

  // Determinamos si estamos en la ruta base del Operador o en una subruta
  const isRootPath = location.pathname === "/Operador";

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('usuario');
  };

  return (
    <div className="container">
      <div className="sidebar">
        <div className="sidebar-header">
          <img src="/headerLogo.png" alt="Logo Empresa" className="logo" />
          <h2>Panel de Administración</h2>
        </div>
        <div>
          <Link to="usuarios" className="link">
            <FaUser className="link-icon" />
            Usuarios
          </Link>
          <Link to="doctores" className="link">
            <FaStethoscope className="link-icon" />
            Doctores
          </Link>
          <Link to="hospitales" className="link">
            <FaClinicMedical className="link-icon" />
            Hospitales
          </Link>
          <Link to="pacientes" className="link">
            <FaUserInjured className="link-icon" />
            Pacientes
          </Link>
          <Link to="DetalleHospital" className="link">
            <FaHospital className="link-icon" />
            Detalle Hospital
          </Link>
          <Link to="recursos" className="link">
            <FaImage className="link-icon" />
            Recursos
          </Link>
          <Link to="noticias" className="link">
            <FaNewspaper className="link-icon" />
            Noticias
          </Link>
          <Link to="reportes" className="link">
            <FaPaperPlane className="link-icon" />
            Reportes
          </Link>
        </div>

        <Link to="/" onClick={handleLogout} className="bye">
          <FaSignOutAlt className="link-icon" />
          Cerrar Sesión
        </Link>
      </div>

      <div className="content">

        {isRootPath && (
          <>
            <h1 className="welcome-text">Bienvenido al módulo de administración</h1>
            <p className="instructions">Seleccione una opción del menú para continuar</p>
          </>
        )}

        <Outlet />
      </div>
    </div>
  );
};

export default Operador;