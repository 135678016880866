import React from 'react';
import Header from '../header';
import '../estilos/inicio.css';

function Inicio() {
  return (
    <div className="fondo-mas">
      <Header/>
        <h2 className='titulos'>Bienvenido a HC Folder</h2>
      <div className='grid'>
      <p className="texto">¿Te imaginas tener más tiempo para lo que realmente importa? Con HC Folder, ahora puedes gestionar fácilmente cada aspecto de tu consulta médica desde un solo lugar.</p>
      <p className="texto">¡Transforma tu consulta hoy y experimenta la comodidad de una gestión moderna, segura y sin complicaciones!</p>
      <p className="texto">Te ayudamos a ser más eficiente, para que puedas centrarte en lo más importante: el cuidado de tus pacientes.</p>
      </div>
      <div className="info-section">
        <div className="info-card">
          <img src="/medicament.jpg" alt="Gestión de salud" />
          <h5>Control total</h5>
          <p>Organiza tus citas de manera eficiente.</p>
        </div>
        <div className="info-card">
          <img src="/doc.jpg" alt="Recetas" />
          <h5>Recetas médicas</h5>
          <p>Expide recetas electrónicas en segundos.</p>
        </div>
        <div className="info-card">
          <img src="/calendar.jpg" alt="Citas" />
          <h5>Tu eliges tus citas</h5>
          <p>Elige entre cientos de citas disponibles.</p>
        </div>
      </div>
      <iframe
        title="Formulario de suscripción HC Folder"
        width="540"
        height="305"
        src="https://14df18d2.sibforms.com/serve/MUIFAFGgzY7OtvGGJ9-ABYKBMyOLInm2Vi_oZJhEvNrg_O_ihmo33A_z4ERYHOX2dgPJAsKWB22BRnFpjagrWfhY4CX51P3JRpWq4cygd3QIXRpS012K4s3im4I1mQ8xBE7y6Vbgtwuhp7yIZ71Z-6SxSlevbLbhG92bnFHTlwJK-DNymmhI-eKEb9tsQ5neKQhWis--HaMIhe1r"
        style={{ marginTop: '60px', maxWidth: '100%', height: '420px' }}
      />

</div>
  );
}

export default Inicio;
